.timeline {
  color: #464159;
  font-family: 'Yanone Kaffeesatz', sans-serif;
}

.timeline p {
  font-size: 20px;
}

.timeline-element {
  color: #4c8492;
}