.App {
  /* background-image: url('https://images.unsplash.com/photo-1487447054015-16918f0e7999?auto=format&fit=crop&w=1351&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D'); */
}

.headshot {
  border-radius: 20%;
  height: 200px;
}

.App-header {
  background-color: #e7edee;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  text-align: center;
  color: #464159;
}

.midsection {
  text-align: center;
}

.about-me {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 20px;
  text-align: justify;
}

.about-me a {
  color: #2e515a;
  text-decoration: none;
}

.social-links {
  margin-top: 60px;
  text-align: center;
}

@media (max-width: 768px) {
  .social-links a {
    display: block;
    float: left;
    font-size: 50px;
    margin-left: 20px;
    padding: 5px;
  }
}

.socials {
  color: #4c8492;
  font-size: 50px;
  padding: 30px;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.socials:hover {
  color: #f7e0a3;
  opacity: 0.5;
}

.title {
  font-family: 'Cinzel Decorative', cursive;
  margin-top: 15px;
}