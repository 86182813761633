.navbar {
  background-color: #4c8492;
}

.navbar a{
  color: #f7e0a3;
}

.signature {
  font-family: 'Cinzel Decorative', cursive;
  font-size: 2em;
}

.links {
  font-family: 'Cinzel Decorative', cursive;
  margin-left: 75%; 
}